@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: normal;
    src: url('./fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    src: url('./fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
